import React,{Component} from 'react';
import {EmailShareButton,FacebookShareButton,LinkedinShareButton,TelegramShareButton,TwitterShareButton,WhatsappShareButton} from "react-share";
import {FacebookShareCount} from "react-share";
import {EmailIcon,FacebookIcon,LinkedinIcon,TelegramIcon,TwitterIcon,WhatsappIcon,} from "react-share";
import '../Booking/Reservation.css';
import Header from '../Header';
import moment from 'moment';



const OneBlogurl = "https://beckettapi.onrender.com/Getblog?OneBlog=";
const OneComment = "https://beckettapi.onrender.com/GetApprovedComments?OneApproved=";
const postCommentUrl = "https://beckettapi.onrender.com/commentPost";
const shareUrl = "https://thebeckettsuites.com/BlogStory/";


class DeGlenBlogStory extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside ROMCOnstructor")
        this.state={
            id:Math.floor(Math.random()*100000),
            _id:Math.floor(Math.random()*100000000),
            LinkUrl:'',
           
           
            clickedTitle:'',
            clickedImage:'',
            clickedContent:'',
            clickedDate:'',

           
            commentAdd:'',
            Names:'',
            approvedComments:''
           
           
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})

    }

    renderApprovedComments=(data)=>{
        if(data){
            
            data.sort((a, b) => new Date(b.date) - new Date(a.date));
            return data.map((item)=>{
                
                return(
                    <>
                        <p className='commentText commentHeading'>
                            <b>{item.sender}:</b>
                        </p>
                        <p className='commentText'>
                            <textarea className="form-control" rows="3">{item.comment}</textarea>
                        </p>
                    </>
                )
                
            })
            
        }
           
    }



    async handleSubmit() {
        
        try {
            
            var comment = {
                commentID:sessionStorage.getItem('getThisBlog'),
                _id:`${this.state._id}`,
                blogComment:`${this.state.commentAdd}`,
                senderNames:`${this.state.Names}`,
                title:`${this.state.clickedTitle}`,
                date: moment(new Date()).format('MMM DD YYYY')
               
            }

            let result = await fetch(postCommentUrl, {
                method: 'post',
                
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    comment
                )
            });
            
            console.log('result>  ' + result)
            alert("Comment Submitted For Approval")
            
            this.myTimer = setTimeout(() => {
                
                this.setState({
                   commentAdd:'',
                   Names:''
                    
                })

            },1000)

            
        } catch(e) {
            console.log(e)
        }
    }

    render(){
        console.log (">>> Inside ROMdetails", this.state)
        
        return(
            <div>
                
                <Header/>
                <div className='blogpage'>
                    <div className='row'>
                        <div className='card resizeCard col-6'>
                            <img src={this.state.clickedImage} className="card-img-top imagenewsize" alt="pix"/>
                            <div className="card-body">
                                <h5 className="card-title moveTitle">{this.state.clickedTitle}</h5>
                                <p className="card-text adjustText">Date Posted: {this.state.clickedDate}</p>
                                <center>
                                <hr/>
                                <span className='gap'>
                                    <FacebookShareButton url={this.state.LinkUrl}>
                                    
                                        <span>
                                            <FacebookIcon size="32px" round={true}/>
                                        </span>
                                        <span>
                                            <FacebookShareCount url={this.state.LinkUrl} />
                                        </span>
                                    </FacebookShareButton>
                                    
                                </span>
                                <span className='gap'>
                                    <LinkedinShareButton url={this.state.LinkUrl}>
                                        <LinkedinIcon size="32px" round={true}/>
                                    </LinkedinShareButton>
                                    
                                </span>
                                <span className='gap'>
                                    <TelegramShareButton url={this.state.LinkUrl}>
                                        <TelegramIcon size="32px" round={true}/>
                                    </TelegramShareButton>
                                    
                                </span>
                                <span className='gap'>
                                    <TwitterShareButton url={this.state.LinkUrl}>
                                        <TwitterIcon size="32px" round={true}/>
                                    </TwitterShareButton>
                                    
                                </span>
                                <span className='gap'>
                                    <WhatsappShareButton url={this.state.LinkUrl}>
                                        <WhatsappIcon size="32px" round={true}/>
                                    </WhatsappShareButton>
                                    
                                </span>
                                <span className='gap'>
                                    <EmailShareButton url={this.state.LinkUrl}>
                                        <EmailIcon size="32px" round={true}/>
                                    </EmailShareButton>
                                    
                                </span>
                                </center>
                                
                            </div>
                            

                        </div>
                        <div className='col-6 flipLeft makemobile'>
                            <h2 className='mb-4 headingFormat'>{this.state.clickedTitle}</h2>
                            <p className='contentText'>{this.state.clickedContent}</p>
                            <br/>
                            <hr/>
                            <p className='slogan'>Powered by The Beckett Suites</p>
                            <br/>
                            <h5 className='mb-4'>Comments</h5>
                           <div className='addComments mb-5'>
                                {this.renderApprovedComments(this.state.approvedComments)}
                            </div>
                           <hr/>
                            <textarea className="form-control mb-4" rows="4" name="commentAdd" require placeholder= "Add New Comments Here" value={this.state.commentAdd} onChange={this.handleChange}/>
                            <input type="text" className="form-control mb-4" name="Names" require placeholder= "Enter Names Here" value={this.state.Names} onChange={this.handleChange}/>
                            <button disabled={this.state.commentAdd===''||this.state.Names===''}type="button" className="btn btn-danger" onClick={ () => this.handleSubmit() }>Post</button>
                            <button type="button" className="btn btn-warning gap" onClick={ () => this.props.history.push('/DeGlenBlog') }>Close</button>
                            

                        </div>
                    </div>

                </div>
                
                             
            </div>
            
        )

    }
    componentDidMount(){
       
        var blogGet = this.props.match.params.id;
        fetch(`${OneBlogurl}${blogGet}`, {method:'GET'})
         .then((resp) => resp.json())
         .then((data) => {
            data.map((item)=>{
                var thisDate = moment(item.date).format('MMM DD YYYY')
                var LinkUp = `${shareUrl}${blogGet}`
                this.setState({
                    clickedImage:item.image,
                    clickedDate:thisDate,
                    clickedTitle:item.title,
                    clickedContent:item.content,
                    LinkUrl:LinkUp
                    
                })
                return 'ok'
               
            })
            return 'ok'
             
        })

        fetch(`${OneComment}${blogGet}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
           
            this.setState({
                approvedComments:data
                  
            })
        })
            
    }

}

export default DeGlenBlogStory;
   
   
   
    
   
   
  