import React from 'react';
import './Header.css';


const Footer = () => {
    return(
        <div style={{width:'100%',display:'inline-block',color:"black"}}>
            <hr/>
            <div>
                <center>
                    <h6>&copy; The Beckett Suites&nbsp;({new Date().getFullYear()})</h6>
                    <i className="fa fa-map-marker"></i> &nbsp;No 1 Mamman Ali Street, Dawaki, FCT Abuja, Nigeria.
                    <p><i className="fa fa-phone"></i>&nbsp;+234-903-133-1659 | +234-701-898-9999 | +234-905-544-9996</p>
                    <div>
                    <span classname="twitr">
                            <a href="http://www.twitter.com" target='_blank' rel="noopener noreferrer" aria-label='twitter'>
                                <i class="fab fa-twitter"></i>
                            </a>
                        </span>&nbsp; &nbsp;
                    
                    
                        <span classname="facebk">
                            <a href="https://www.facebook.com/thebeckettsuites" target='_blank' rel="noopener noreferrer" aria-label='facebook'>
                                <i class="fab fa-facebook"></i>
                            </a>
                            
                        </span>&nbsp; &nbsp;
                    
                    
                        <span classname="insta">
                            <a href="https://www.instagram.com/thebeckettsuites/" target='_blank' rel="noopener noreferrer" aria-label='insta'>
                                <i class="fab fa-instagram"></i>
                            </a>
                        </span>
                    </div>
                </center>
                
            </div>
        </div>
    )
}

export default Footer;