import React,{Component} from 'react';
import '../Booking/Reservation.css';
import Header2 from '../Header2';
import { Link } from 'react-router-dom';
import moment from 'moment';


const PostBlogurl = "https://beckettapi.onrender.com/blogPost"
const GetBlogurl = "https://beckettapi.onrender.com/Getblog?OneBlog="
const GetComments = "https://beckettapi.onrender.com/GetblogComments?ActiveComment="
const deleteComm = "https://beckettapi.onrender.com/deleteComment"
const updateBlogger = "https://beckettapi.onrender.com/updateComments"
const delUrl = "https://beckettapi.onrender.com/delBlog"


class Blogger extends Component {
    
    constructor(props) {
        super(props);
        console.log (">>>Inside ROMCOnstructor")
        this.state={
            id:Math.floor(Math.random()*100000),
            image:'',
            title:'',
            content:'',
            comments:[],
            date:new Date(),
           
            blogFiles:'',
            blogComments:'',

           
            
            
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    async handleSubmit() {
        
        try {
            var addedComments = this.state.comments;
            var blog = {
                _id:`${this.state.id}`,
                image:`${this.state.image}`,
                title:`${this.state.title}`,
                content:`${this.state.content}`,
                date: moment(this.state.date).format('MMM-DD-YYYY'),
                comments:addedComments.map(item=>item)
               
            }

            let result = await fetch(PostBlogurl, {
                method: 'post',
                
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                },

                body: JSON.stringify(
                    blog
                )
            });
            
            console.log('result>  ' + result)
            alert("Post Submitted Successfully")
            this.setState({
                image:'',
                title:'',
                content:'',
                date:''
               
                
            })
            this.myTimer = setTimeout(() => {
                
                this.setState({
                    id:Math.floor(Math.random()*10000)
                    
                   
                    
                })

                fetch(`${GetBlogurl}`, {method:'GET'})
                .then((resp) => resp.json())
                .then((data) => {
                    this.setState({
                        blogFiles:data
                    })
                    
                })

            },1000)

           
           
            
            
        } catch(e) {
            console.log(e)
        }
    }

    deleteBlog(){
        var Blogid=sessionStorage.getItem('delBlog')
        fetch(`${delUrl}/${Blogid}`, {method:'delete'});
        alert('Blog Deleted');

        this.myTimer = setTimeout(() => {
           
            fetch(`${GetBlogurl}`, {method:'GET'})
            .then((resp) => resp.json())
            .then((data) => {
                this.setState({
                    blogFiles:data
                })
                
            })

        },1000)
        
    }

    updateBlog(){

        var Commentor=sessionStorage.getItem('updateBlog')
        
        fetch(`${GetComments}${Commentor}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
            this.setState({thisBlog:data})
            data.map((item)=>{
                var activecomment = [];
                var approved = {};
                approved.sender = item.senderNames;
                approved.comment = item.blogComment;
                approved.commentID = item.commentID;
                activecomment.push(approved);
                this.setState({comments:activecomment})
                return 'ok'
            })

            this.myTimer = setTimeout(() => {

                var addedComments = this.state.comments;
                addedComments.map((item)=>{
                    var blogUpdate = {
                        id:item.commentID,
                        comment:item.comment,
                        sender:item.sender,
                        date: moment(this.state.date).format('MMM-DD-YYYY')
                       
                    }
                    let result = fetch(updateBlogger, {
                        method: 'post',
                        
                        headers: {
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                        },
    
                        body: JSON.stringify(
                            blogUpdate
                        )
                    });
                    console.log('result>  ' + result)
                    alert("Blog Published Successfully")
                    this.setState({
                        image:'',
                        title:'',
                        content:'',
                        comments:'',
                        date:'',
                        id:Math.floor(Math.random()*100000)
                
                    
                    })
                   
                    
                    return 'ok'

                })

            },1000)

            this.myTimer = setTimeout(() => {
                fetch(`${deleteComm}/${Commentor}`, {method:'delete'});
                
                fetch(`${GetComments}`, {method:'GET'})
                .then((resp) => resp.json())
                .then((data) => {
                    this.setState({
                        blogComments:data
                    })
                    
                })

            },1000)


           
                
        })

        
        
    }



   
    renderBlogs=(data)=>{
        if(data){
            
            data.sort((a, b) => new Date(b.date) - new Date(a.date));
            return data.map((item)=>{
                var date = moment(item.date).format('YYYY-DD-MMM')
                
                return(
                    <>
                        <tr key= {item._id}>
                                
                            <td className="table-light table-striped adjust2">{date}</td>
                            <td className="table-light table-striped adjust2">{item.title}</td>
                           <td className="table-light table-striped adjust10"><button className="btn btn-danger textcolor2" onMouseOver={()=>sessionStorage.setItem('delBlog', item._id)} onClick={()=>{this.deleteBlog()}}>Delete</button></td>
                                
                        </tr>
                    </>
                )
                
            })
            
        }
           
    }

    renderComments=(data)=>{
        if(data){
            
            data.sort((a, b) => new Date(a.date) - new Date(b.date));
            return data.map((item)=>{
                var date = moment(item.date).format('YYYY-DD-MMM')
                
                return(
                    <>
                        <tr key= {item._id}>
                                
                            <td className="table-light table-striped adjust2">{date}</td>
                            <td className="table-light table-striped adjust2">{item.commentID}</td>
                            <td className="table-light table-striped adjust2">{item.title}</td>
                            <td className="table-light table-striped adjust2">{item.blogComment}</td>
                            <td className="table-light table-striped adjust2">{item.senderNames}</td>
                           <td className="table-light table-striped adjust10"><button className="btn btn-primary textcolor" onMouseOver={()=>sessionStorage.setItem('updateBlog', item._id)} onClick={()=>{this.updateBlog()}}>Publish</button></td>
                                
                        </tr>
                    </>
                )
                
            })
            
        }
           
    }

    

    render(){
        console.log (">>> Inside ROMdetails", this.state)
        if(localStorage.getItem('rtk') == null){
            return(
                <>
                    <Header2/>
                    <div className="background10">
                        <div>
                            <Link to="/Admin" className="textdec"><h3 style={{color:"silver"}}>You must login </h3><i className="fa fa-mouse-pointer awesomefmt"></i></Link>
                        </div>
                
                    </div>
                </>
                
            )
        }
        return(
            <>
                <Header2/>
                <div className="container">
                    <div className='blog0'>
                        <div className="row">
                            <b><center><div className="meeetingtitle">Post Blog</div></center></b>
                            
                        </div>
                        <div className="row">
                            
                            <div className ="col-5 aboutfmt blog1">
                                <h6>Add Image</h6>
                                <input type="text" className="form-control mb-4" name="image" require placeholder="Add an Image Link Here" value={this.state.image} onChange={this.handleChange}/>

                                <h6>Add Title</h6>
                                <input type="text" className="form-control mb-4" name="title" require placeholder= "Add Blog Title" value={this.state.title} onChange={this.handleChange}/>

                                <h6>Add Content</h6>
                                <textarea className="form-control mb-4" rows="6" name="content" require placeholder= "Add Content" value={this.state.content} onChange={this.handleChange}/>


                                <center><button disabled={this.state.image===''||this.state.title===''||this.state.content===''}type="button" className="btn btn-danger" onClick={ () => this.handleSubmit() }>Post</button></center>
                            </div>

                            <div className ="col-5 aboutfmt blog2">
                                <div className='adjustcol'>
                                <h6>Active Blogs</h6>
                                    <table className="table table-hover">
                                    
                                        <thead className="table-warning">
                                            <tr>
                                                <th className="adjust5">Date</th>
                                               <th className="adjust5">Title</th>
                                                <th className="adjust5">Action</th>
                                           
                                            </tr>
                                        </thead>
                                        <tbody className="table table-hover">
                                            {this.renderBlogs(this.state.blogFiles)}
                                        
                                        </tbody>
                                    </table>
                                </div>


                               
                            </div>

                            <div>
                                <div className ="aboutfmt blog2 blog9">
                                    <div className='adjustcol'>
                                    <h6>Active Comments</h6>
                                        <table className="table table-hover">
                                        
                                            <thead className="table-warning">
                                                <tr>
                                                    <th className="adjust5">Date</th>
                                                    <th className="adjust5">ID</th>
                                                    <th className="adjust5">Title</th>
                                                    <th className="adjust5">Comment</th>
                                                    <th className="adjust5">Names</th>
                                                    <th className="adjust5">Action</th>
                                            
                                                </tr>
                                            </thead>
                                            <tbody className="table table-hover">
                                                {this.renderComments(this.state.blogComments)}
                                            
                                            </tbody>
                                        </table>
                                    </div>


                                
                                </div>
                            </div>
                                        
                        </div>
                    </div>
                </div>
                             
            </>
            
        )

    }
    componentDidMount(){
        console.log(">>> Inside GrpDidMount", this.state);
        fetch(`${GetBlogurl}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
            this.setState({
                blogFiles:data
            })
            
        })

        fetch(`${GetComments}`, {method:'GET'})
        .then((resp) => resp.json())
        .then((data) => {
            this.setState({
                blogComments:data
            })
            
        })

    }


   
}

export default Blogger;

